exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-foundations-brand-icons-mdx": () => import("./../../../src/pages/foundations/brand-icons.mdx" /* webpackChunkName: "component---src-pages-foundations-brand-icons-mdx" */),
  "component---src-pages-foundations-colors-tsx": () => import("./../../../src/pages/foundations/colors.tsx" /* webpackChunkName: "component---src-pages-foundations-colors-tsx" */),
  "component---src-pages-foundations-css-utilities-mdx": () => import("./../../../src/pages/foundations/css-utilities.mdx" /* webpackChunkName: "component---src-pages-foundations-css-utilities-mdx" */),
  "component---src-pages-foundations-elevation-tsx": () => import("./../../../src/pages/foundations/elevation.tsx" /* webpackChunkName: "component---src-pages-foundations-elevation-tsx" */),
  "component---src-pages-foundations-icons-mdx": () => import("./../../../src/pages/foundations/icons.mdx" /* webpackChunkName: "component---src-pages-foundations-icons-mdx" */),
  "component---src-pages-foundations-layout-mdx": () => import("./../../../src/pages/foundations/layout.mdx" /* webpackChunkName: "component---src-pages-foundations-layout-mdx" */),
  "component---src-pages-foundations-lms-icons-mdx": () => import("./../../../src/pages/foundations/lms-icons.mdx" /* webpackChunkName: "component---src-pages-foundations-lms-icons-mdx" */),
  "component---src-pages-foundations-responsive-tsx": () => import("./../../../src/pages/foundations/responsive.tsx" /* webpackChunkName: "component---src-pages-foundations-responsive-tsx" */),
  "component---src-pages-foundations-spacing-tsx": () => import("./../../../src/pages/foundations/spacing.tsx" /* webpackChunkName: "component---src-pages-foundations-spacing-tsx" */),
  "component---src-pages-foundations-typography-tsx": () => import("./../../../src/pages/foundations/typography.tsx" /* webpackChunkName: "component---src-pages-foundations-typography-tsx" */),
  "component---src-pages-guides-getting-started-mdx": () => import("./../../../src/pages/guides/getting-started.mdx" /* webpackChunkName: "component---src-pages-guides-getting-started-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-tsx": () => import("./../../../src/pages/insights.tsx" /* webpackChunkName: "component---src-pages-insights-tsx" */),
  "component---src-pages-playground-tsx": () => import("./../../../src/pages/playground.tsx" /* webpackChunkName: "component---src-pages-playground-tsx" */),
  "component---src-pages-status-tsx": () => import("./../../../src/pages/status.tsx" /* webpackChunkName: "component---src-pages-status-tsx" */),
  "component---src-pages-tools-component-generator-mdx": () => import("./../../../src/pages/tools/component-generator.mdx" /* webpackChunkName: "component---src-pages-tools-component-generator-mdx" */),
  "component---src-templates-component-page-template-tsx": () => import("./../../../src/templates/component-page-template.tsx" /* webpackChunkName: "component---src-templates-component-page-template-tsx" */)
}

