module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","icon":"src/images/paragon-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7044a52a4f136c9007480f861070f8da"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"components":"/opt/build/repo/www/src/templates/component-page-template.tsx","default":"/opt/build/repo/www/src/templates/default-mdx-page-template.tsx"},"rehypePlugins":[null,[null,{"behavior":"append","content":{"type":"element","tagName":"span","properties":{"className":"pgn-doc__anchor"},"children":[{"type":"text","value":"#"}]}}]],"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo/www","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"AY3eGQbKBjwGfG89hulrELLCynpRVTsE","devKey":"AY3eGQbKBjwGfG89hulrELLCynpRVTsE","trackPage":true,"trackPageDelay":100},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
